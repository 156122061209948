<template>
    <b-card>
        <b-row>
            <b-col>
                <peoples-kms-table />
            </b-col>
        </b-row>
    </b-card>
</template>

<script>
import {
    BRow,
    BCol,
    BButton,
    BDropdown,
    BDropdownItem,
    BCard,
} from "bootstrap-vue"
import PeoplesKmsTable from "@/components/PeoplesKmsTable.vue"

export default {
    name: "PeopleKms",
    components: {
        BRow,
        BCol,
        BButton,
        PeoplesKmsTable,
        BDropdown,
        BDropdownItem,
        BCard,
    },
    data() {
        return {}
    },
    created() {},
    methods: {},
}
</script>
