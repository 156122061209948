<template>
    <div>
        <!-- search input -->
        <div class="custom-search d-flex">
            <div class="d-flex mr-auto">
                <div>
                    <b-dropdown
                        id="dropdown-7"
                        v-ripple.400="'rgba(255, 255, 255, 0)'"
                        text="Actions"
                        variant="secondary"
                    >
                        <b-dropdown-item>Select action:</b-dropdown-item>
                        <b-dropdown-item :disabled="!approve" @click="save(3)">
                            <feather-icon
                                class="text-success mr-right-10"
                                icon="CheckIcon"
                            />Approve
                        </b-dropdown-item>
                        <b-dropdown-item :disabled="!decline" @click="save(4)">
                            <feather-icon
                                class="text-danger mr-right-10"
                                icon="XIcon"
                            />Decline
                        </b-dropdown-item>
                        <b-dropdown-item
                            :disabled="!nudge"
                            @click="nudgeAction()"
                        >
                            <feather-icon
                                class="text-warning mr-right-10"
                                icon="SendIcon"
                            />Nudge
                        </b-dropdown-item>
                        <b-dropdown-item :disabled="!reopen" @click="save(1)">
                            <feather-icon
                                class="text-info mr-right-10"
                                icon="RotateCwIcon"
                            />Reopen
                        </b-dropdown-item>
                        <b-dropdown-item
                            :disabled="!remove"
                            @click="removeAction()"
                        >
                            <feather-icon
                                class="text-danger mr-right-10"
                                icon="Trash2Icon"
                            />Delete
                            <span class="font-small-2 text-danger"
                                >This action is irreversible</span
                            >
                        </b-dropdown-item>
                    </b-dropdown>
                </div>
                <div>
                    <b-button
                        v-if="prevActive"
                        v-ripple.400="'rgba(30, 30, 30, 0)'"
                        variant="flat-secondary"
                        class="btn-icon rounded-circle ml-1"
                        @click="previousMonth"
                    >
                        <feather-icon icon="ChevronLeftIcon" size="1.5x" />
                    </b-button>
                    <b-button
                        v-else
                        v-ripple.400="'rgba(30, 30, 30, 0)'"
                        variant="flat-secondary"
                        class="btn-icon rounded-circle ml-1"
                        disabled
                        @click="previousMonth"
                    >
                        <feather-icon icon="ChevronLeftIcon" size="1.5x" />
                    </b-button>
                </div>
                <div>
                    <b-button
                        v-if="nextActive"
                        v-ripple.400="'rgba(30, 30, 30, 0)'"
                        variant="flat-secondary"
                        class="btn-icon rounded-circle"
                        @click="nextMonth"
                    >
                        <feather-icon icon="ChevronRightIcon" size="1.5x" />
                    </b-button>
                    <b-button
                        v-else
                        v-ripple.400="'rgba(30, 30, 30, 0)'"
                        variant="flat-secondary"
                        class="btn-icon rounded-circle"
                        disabled
                        @click="nextMonth"
                    >
                        <feather-icon icon="ChevronRightIcon" size="1.5x" />
                    </b-button>
                </div>
                <div>
                    <b-button
                        v-if="date"
                        id="monthDate"
                        v-ripple.400="'rgba(30, 30, 30, 0)'"
                        style="margin-top: -5px"
                        size="lg"
                        variant="flat-secondary"
                        class="btn-icon mb-1"
                        @click="
                            monthPicker = monthPicker === true ? false : true
                        "
                    >
                        {{ date | monthYearPeopleFormat }}
                        <feather-icon icon="ChevronDownIcon" size="1x" />
                    </b-button>
                    <div
                        v-if="monthPicker && date && monthsMax && monthsMin"
                        style="position: absolute; z-index: 9999"
                    >
                        <Calendar
                            v-model="month"
                            view="month"
                            date-format="mm/yy"
                            :inline="true"
                            :min-date="
                                new Date(
                                    new Date(monthsMin).setMonth(
                                        new Date(monthsMin).getMonth()
                                    )
                                )
                            "
                            :max-date="new Date(monthsMax)"
                        />
                        <!-- Form Actions -->
                        <div
                            class="d-flex custom-month-picker"
                            style="flex-direction: row-reverse"
                        >
                            <b-button
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                class="margin-left-10"
                                variant="primary"
                                size="sm"
                                @click="showDate(month)"
                            >
                                Apply
                            </b-button>
                            <b-button
                                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                                variant="outline-secondary"
                                size="sm"
                                @click="
                                    monthPicker =
                                        monthPicker === true ? false : true
                                "
                            >
                                Cancel
                            </b-button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex align-items-center">
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0)'"
                    v-b-modal.new-mileage
                    variant="outline-primary"
                    class="mr-1 mb-15"
                >
                    <feather-icon icon="UserPlusIcon" class="mr-right-10" />
                    <span class="align-middle">New mileage</span>
                </b-button>
                <b-form-group>
                    <b-form-input
                        v-model="searchTerm"
                        placeholder="Search"
                        type="text"
                        class="d-inline-block"
                    />
                </b-form-group>
            </div>
        </div>
        <b-modal
            id="new-mileage"
            centered
            size="lg"
            title="New mileage"
            cancel
            cancel-variant="outline-secondary"
            ok-title="Create"
            @ok="createMileage"
        >
            <b-card-text class="font-weight-bolder">
                Create a new mileage report for a specific employee or group of
                employees.
            </b-card-text>
            <b-card-text class="min-height-modal mt-1">
                <b-row>
                    <b-col cols="8">
                        <b-form-group>
                            <MultiSelect
                                v-model="employeesSelected"
                                :options="employees"
                                :filter="true"
                                option-label="name"
                                placeholder="Select employees*"
                                display="chip"
                                style="max-width: 510px; min-width: 510px"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col>
                        <b-form-group>
                            <Calendar
                                v-model="cycle"
                                view="month"
                                placeholder="Select cycle*"
                                date-format="mm/yy"
                                class="ml-1"
                            />
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-card-text>
        </b-modal>
        <!-- table -->
        <vue-good-table
            v-if="rows.length > 0 && tableGood"
            :columns="columns"
            :rows="rows"
            :rtl="direction"
            :search-options="{
                enabled: true,
                searchFn: searchFn,
                externalQuery: searchTerm,
            }"
            :select-options="{
                enabled: true,
                selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
                selectionInfoClass: 'custom-class',
                selectionText: 'rows selected',
                clearSelectionText: 'clear',
                disableSelectInfo: true, // disable the select info panel on top
                selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
            }"
            :pagination-options="{
                enabled: true,
                perPage: pageLength,
            }"
            @on-selected-rows-change="selectionChanged"
        >
            <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field === 'status'">
                    <b-badge
                        :variant="statusVariant(props.row.status.description)"
                    >
                        {{ props.row.status.description }}
                    </b-badge>
                </span>
                <span
                    v-else-if="props.column.field === 'employee.name'"
                    v-b-tooltip.hover.right
                    title="Click to check mileage report!"
                    style="cursor: pointer"
                    @click="
                        $router.push(
                            '/forms/mileage/' +
                                props.row.employee.id +
                                '/' +
                                props.row.date.split('-')[1] +
                                '/' +
                                props.row.date.split('-')[0]
                        )
                    "
                >
                    <b-avatar
                        class="mr-right-10"
                        size="36px"
                        :src="props.row.employee.avatar"
                    />
                    {{ props.row.employee.name }}
                </span>
                <span
                    v-else-if="
                        props.column.field === 'employee.reports_to.name'
                    "
                    class="d-flex"
                >
                    <b-avatar
                        v-if="props.row.employee.reports_to"
                        class="mr-right-10"
                        size="36px"
                        :src="props.row.employee.reports_to.avatar"
                    />
                    <div
                        v-if="props.row.employee.reports_to"
                        class="name-vt-align"
                    >
                        {{ props.row.employee.reports_to.name }}
                    </div>
                </span>
                <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                </span>
            </template>

            <!-- pagination -->
            <template slot="pagination-bottom" slot-scope="props">
                <div class="d-flex justify-content-between flex-wrap">
                    <div v-if="selected.length > 0" class="mt-43">
                        Selected: {{ selected.length }}
                    </div>
                    <div v-else class="mt-43">Total: {{ rows.length }}</div>
                    <div>
                        <b-pagination
                            :value="1"
                            :total-rows="props.total"
                            :per-page="pageLength"
                            first-number
                            last-number
                            align="right"
                            prev-class="prev-item"
                            next-class="next-item"
                            class="mt-1 mb-0"
                            @input="
                                (value) =>
                                    props.pageChanged({ currentPage: value })
                            "
                        >
                            <template #prev-text>
                                <feather-icon
                                    icon="ChevronLeftIcon"
                                    size="18"
                                />
                            </template>
                            <template #next-text>
                                <feather-icon
                                    icon="ChevronRightIcon"
                                    size="18"
                                />
                            </template>
                        </b-pagination>
                    </div>
                </div>
            </template>
        </vue-good-table>
        <div v-else-if="tableGood">
            <b-alert variant="secondary" show>
                <div class="alert-body mt-1" style="margin-bottom: -14px">
                    <span><strong>Sorry!</strong> No results found.</span>
                </div>
            </b-alert>
        </div>
    </div>
</template>

<script>
import {
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BButton,
    BCard,
    VBTooltip,
    VBModal,
    BAlert,
    BModal,
    BCardText,
} from "bootstrap-vue"
import vSelect from "vue-select"
import { VueGoodTable } from "vue-good-table"
import store from "@/store/index"
import Km from "@/api/services/Km"
import Auth from "@/api/services/Auth"
import Ripple from "vue-ripple-directive"
import { MonthPicker } from "vue-month-picker"
import moment from "moment"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import DatePicker from "vue2-datepicker"
import "vue2-datepicker/index.css"
import Calendar from "primevue/calendar"
import "primevue/resources/themes/bootstrap4-light-blue/theme.css"
import "primevue/resources/themes/saga-blue/theme.css"
import "primevue/resources/primevue.min.css"
import "primeicons/primeicons.css"
import MultiSelect from "primevue/multiselect"

export default {
    name: "PeoplesKmsTable",
    components: {
        VueGoodTable,
        BAvatar,
        BBadge,
        BPagination,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BDropdown,
        BDropdownItem,
        BRow,
        BCol,
        BButton,
        BCard,
        MonthPicker,
        VBModal,
        BAlert,
        BModal,
        ToastificationContent,
        vSelect,
        BCardText,
        DatePicker,
        Calendar,
        MultiSelect,
    },
    directives: {
        Ripple,
        "b-tooltip": VBTooltip,
        "b-modal": VBModal,
    },
    data() {
        return {
            month: "",
            pageLength: 20,
            dir: false,
            columns: [
                {
                    label: "Status",
                    field: "status",
                    tdClass: "height-50",
                },
                {
                    label: "Employee",
                    field: "employee.name",
                },
                {
                    label: "Department",
                    field: "employee.department",
                },
                {
                    label: "Reports to",
                    field: "employee.reports_to.name",
                },
                {
                    label: "Kms",
                    field: "total_kms",
                    type: "decimal",
                    formatFn: this.formatKm,
                },
                {
                    label: "Total",
                    field: "amount",
                    type: "decimal",
                    formatFn: this.formatEuro,
                },
            ],
            rows: [],
            searchTerm: "",
            monthPicker: false,
            selected: [],
            approve: false,
            decline: false,
            reopen: false,
            nudge: false,
            remove: false,
            employees: [],
            employeesSelected: [],
            cycle: null,
            date: "",
            monthsMin: "",
            monthsMax: "",
            prevActive: true,
            nextActive: true,
            tableGood: false,
        }
    },
    computed: {
        direction() {
            if (store.state.appConfig.isRTL) {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.dir = true
                return this.dir
            }
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.dir = false
            return this.dir
        },
    },
    watch: {
        date(value) {
            let data = moment(this.monthsMin, "YYYY-MM-DD").format("DD-MM-YYYY")
            if (data === "Invalid date") {
                this.prevActive = false
            } else if (value.slice(3) === data.slice(3)) {
                this.prevActive = false
            } else {
                this.prevActive = true
            }
            data = moment(this.monthsMax, "YYYY-MM-DD").format("DD-MM-YYYY")
            if (data === "Invalid date") {
                this.nextActive = false
            } else if (value.slice(3) === data.slice(3)) {
                this.nextActive = false
            } else {
                this.nextActive = true
            }
        },
    },
    created() {
        this.loadData()
    },
    methods: {
        searchFn(row, col, cellValue, searchTerm) {
            if (!searchTerm) {
                return true
            }
            if (
                col.field === "status" &&
                "description" in cellValue &&
                cellValue.description
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase())
            ) {
                return true
            }
            if (col.field === "status") {
                return false
            }
            return cellValue.toLowerCase().includes(searchTerm.toLowerCase())
        },
        createMileage() {
            const year = new Date(this.cycle).getFullYear()
            const month = new Date(this.cycle).getMonth() + 1
            let valid = false
            this.employeesSelected.forEach((item) => {
                if (this.cycle !== null) {
                    valid = true
                    Km.getKms(year, month, item.id)
                } else if (this.cycle === null) {
                    valid = false
                }
            })
            this.employeesSelected = []
            this.cycle = null
            if (valid) {
                setTimeout(() => {
                    this.loadData()
                    this.$toast(
                        {
                            component: ToastificationContent,
                            props: {
                                title: "Success",
                                icon: "CheckCircleIcon",
                                text: "Mileage report has been successfully created! 🥳",
                                variant: "success",
                            },
                        },
                        {
                            position: "top-center",
                        }
                    )
                }, 500)
            } else {
                setTimeout(() => {
                    this.loadData()
                    this.$toast(
                        {
                            component: ToastificationContent,
                            props: {
                                title: "Error: Required field is missing! 🤚",
                                icon: "XIcon",
                                text: "Please select a cycle to create a new mileage.",
                                variant: "danger",
                            },
                        },
                        {
                            position: "bottom-center",
                            timeout: 8000,
                        }
                    )
                }, 500)
            }
        },
        nudgeAction() {
            this.selected.forEach((item) => {
                Km.postNudge({
                    id: item.id,
                })
            })
            this.$toast(
                {
                    component: ToastificationContent,
                    props: {
                        title: "Success",
                        icon: "CheckCircleIcon",
                        text: "Nudge reminder has been successfully sent! 🥳",
                        variant: "success",
                    },
                },
                {
                    position: "top-center",
                }
            )
        },
        removeAction() {
            this.selected.forEach((item) => {
                Km.deletePeoplesKms(item.id).then((response) => {
                    this.loadData()
                })
            })
            this.$toast(
                {
                    component: ToastificationContent,
                    props: {
                        title: "Success",
                        icon: "CheckCircleIcon",
                        text: "Employee's mileage has been successfully deleted! 🥳",
                        variant: "success",
                    },
                },
                {
                    position: "top-center",
                }
            )
        },
        save(id) {
            this.$loading(true)
            this.selected.forEach((element) => {
                Km.updateOrCreatePeoplesKms({
                    id: element.id,
                    is_empty: element.is_empty,
                    date: element.date,
                    status: id,
                }).then(() => {
                    this.loadData()
                    switch (id) {
                        case 1:
                            this.$toast(
                                {
                                    component: ToastificationContent,
                                    props: {
                                        title: "Success",
                                        icon: "CheckCircleIcon",
                                        text: "Employee's mileage has been successfully reopened! 🥳",
                                        variant: "success",
                                    },
                                },
                                {
                                    position: "top-center",
                                }
                            )
                            break
                        case 3:
                            this.$toast(
                                {
                                    component: ToastificationContent,
                                    props: {
                                        title: "Success",
                                        icon: "CheckCircleIcon",
                                        text: "Employee's mileage has been successfully approved! 🥳",
                                        variant: "success",
                                    },
                                },
                                {
                                    position: "top-center",
                                }
                            )
                            break
                        case 4:
                            this.$toast(
                                {
                                    component: ToastificationContent,
                                    props: {
                                        title: "Success",
                                        icon: "CheckCircleIcon",
                                        text: "Employee's mileage has been successfully declined! 😕",
                                        variant: "success",
                                    },
                                },
                                {
                                    position: "top-center",
                                }
                            )
                            break
                        default:
                            break
                    }
                })
            })
        },
        selectionChanged(event) {
            this.selected = event.selectedRows
            let approve = true
            let decline = true
            let reopen = true
            let nudge = true
            let remove = true
            if (this.selected.length === 0) {
                approve = false
                decline = false
                reopen = false
                nudge = false
                remove = false
            }
            this.selected.forEach((item) => {
                if (
                    item.status.description === "Open" ||
                    item.status.description === "Closed" ||
                    item.status.description === "Pending changes"
                ) {
                    approve = false
                    decline = false
                    reopen = false
                }
                if (item.status.description === "Approved") {
                    approve = false
                    nudge = false
                    remove = false
                }
                if (item.status.description === "Declined") {
                    decline = false
                    nudge = false
                    remove = false
                }
                if (
                    item.status.description === "Closed" ||
                    item.status.description === "Approved" ||
                    item.status.description === "Declined" ||
                    item.status.description === "Pending approval"
                ) {
                    nudge = false
                    remove = false
                }
            })
            this.approve = approve
            this.decline = decline
            this.reopen = reopen
            this.nudge = nudge
            this.remove = remove
        },
        previousMonth() {
            const dt = new Date(
                this.date.split("-")[2],
                this.date.split("-")[1] - 1,
                1
            )
            let year = dt.getFullYear()
            let month = dt.getMonth()
            if (month <= 0) {
                year--
                month = 12
            }
            this.loadData(
                moment(new Date(`${year}-${month}-1`), "DD-MM-YYYY").format(
                    "DD-MM-YYYY"
                )
            )
        },
        nextMonth() {
            const dt = new Date(
                this.date.split("-")[2],
                Number(this.date.split("-")[1]),
                1
            )
            let year = dt.getFullYear()
            let month = dt.getMonth() + 1
            if (month > 12) {
                year++
                month = 1
            }
            this.loadData(
                moment(new Date(`${year}-${month}-1`), "DD-MM-YYYY").format(
                    "DD-MM-YYYY"
                )
            )
        },
        showDate(value) {
            if (this.date === "") {
                this.date = moment(
                    new Date(
                        `${value.getFullYear()}-${value.getMonth() + 1}-1`
                    ),
                    "DD-MM-YYYY"
                ).format("DD-MM-YYYY")
            }
            this.loadData(
                moment(
                    new Date(
                        `${value.getFullYear()}-${value.getMonth() + 1}-1`
                    ),
                    "DD-MM-YYYY"
                ).format("DD-MM-YYYY")
            )
            const x = document.getElementsByClassName("mp-index")
            let i
            setTimeout(() => {
                for (i = 0; i < x.length; i++) {
                    x[i].classList.toggle("mp-index")
                }
                // document.getElementById("monthDate").click()
                this.monthPicker = this.monthPicker !== true
            }, 100)
        },
        formatEuro(value) {
            return `${value.toFixed(2)} €`
        },
        formatKm(value) {
            return `${value.toFixed(2)} km`
        },
        statusVariant(status) {
            switch (status) {
                case "Open":
                    return "light-info"
                case "Pending approval":
                    return "light-warning"
                case "Approved":
                    return "light-success"
                case "Declined":
                    return "light-danger"
                case "Closed":
                    return "light-dark"
                case "Pending submission":
                    return "light-secondary"
                case "Pending changes":
                    return "light-secondary"
                default:
                    return "light-primary"
            }
        },
        loadData(dt = moment(new Date(), "DD-MM-YYYY").format("DD-MM-YYYY")) {
            this.tableGood = false
            this.date = ""
            this.$loading(true)
            // pegar todos os dados e preencher a página
            const year = dt.split("-")[2]
            const month = dt.split("-")[1]
            Km.getPeoplesMileage(year, month).then((response) => {
                this.rows = []
                this.rows = response.data.results
                this.tableGood = true
                this.$loading(false)
            })
            Km.getMinMaxDate().then((response) => {
                this.monthsMin = response.data.min
                this.monthsMax = response.data.max
                this.date = moment(
                    new Date(year, month - 1, 1),
                    "DD-MM-YYYY"
                ).format("DD-MM-YYYY")
                this.month = new Date(year, month - 1, 1)
            })
            Auth.employees().then((response) => {
                this.employees = response.data.results.filter(
                    (element) => element.bob_id
                )
            })
        },
    },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-select.scss";
[dir] .vs__dropdown-toggle {
    border: 1px solid #ccc;
}
.xmx-input-wrapper {
    border: 1px solid #ccc;
    border-bottom-left-radius: 0.357rem;
    border-bottom-right-radius: 0.357rem;
    border-top-left-radius: 0.357rem;
    border-top-right-radius: 0.357rem;
}
#vs7__listbox {
    max-height: 140px !important;
}
#vs1__listbox {
    max-height: 140px !important;
}
#vs2__listbox {
    max-height: 140px !important;
}
.custom-month-picker {
    background: #fff;
    padding: 10px;
    border-left: 1px solid #ced4da;
    border-right: 1px solid #ced4da;
    border-bottom: 1px solid #ced4da;
}
[dir] .p-datepicker .p-monthpicker .p-monthpicker-month.p-highlight {
    background: #e9ecef !important;
}
[dir]
    .p-datepicker:not(.p-disabled)
    .p-monthpicker
    .p-monthpicker-month:not(.p-disabled):focus {
    box-shadow: 0 0 0 0.2rem #e9ecef !important;
}
[dir] .p-datepicker .p-yearpicker .p-yearpicker-year.p-highlight {
    background: #e9ecef !important;
}
[dir]
    .p-datepicker:not(.p-disabled)
    .p-yearpicker
    .p-yearpicker-year:not(.p-disabled):focus {
    box-shadow: 0 0 0 0.2rem #e9ecef !important;
}
.p-link:focus {
    box-shadow: 0 0 0 0.2rem #e9ecef !important;
}
.p-datepicker
    .p-datepicker-header
    .p-datepicker-title
    .p-datepicker-year:enabled:hover,
.p-datepicker
    .p-datepicker-header
    .p-datepicker-title
    .p-datepicker-month:enabled:hover {
    color: #78797b !important;
}
.p-component {
    font-family: "cais-sans", sans-serif !important;
}
.p-multiselect {
    border-radius: 5px !important;
}
[dir] .p-multiselect:not(.p-disabled):hover {
    border-color: #e9ecef !important;
}
.p-inputtext:enabled:hover {
    border-color: #e9ecef !important;
}
.p-inputtext:enabled:focus {
    box-shadow: 0 0 0 0.2rem #e9ecef !important;
    border-color: #c5c8ca !important;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
    background: #e9ecef !important;
}
.p-checkbox .p-checkbox-box.p-highlight {
    border-color: #78797b !important;
    background: #78797b !important;
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
    border-color: #78797b !important;
}
[dir] .p-multiselect:not(.p-disabled).p-focus {
    box-shadow: none !important;
}
[dir] .p-inputtext {
    border-radius: 5px !important;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus {
    box-shadow: none !important;
}
input[type="checkbox"] {
    accent-color: #78797b !important;
}
@import "@/assets/scss/variables/variables.scss";

$namespace: "xmx"; // change the 'mx' to 'xmx'. then <date-picker prefix-class="xmx" />

$default-color: #555;
$primary-color: $primary;

.xmx-datepicker-main {
    font:
        14px/1.5 "cais-sans",
        sans-serif !important;
}

[dir] .xmx-input {
    box-shadow: none !important;
}
@import "~vue2-datepicker/scss/index.scss";
</style>
