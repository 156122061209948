import Api from "@/api"
import * as Constants from "@/store/constants"

const KMS = "/forms/mileage/"
const KMENTRIES = "forms/entries/kms/"
const KMENTRIES_EXPORT = "forms/export/mileage/"
const MESSAGE = "mileage/message/"
const PEOPLESKMS = "/forms/peoples/mileage/"
// const PEOPLESMILEAGE = '/forms/peoples/mileage/'
const NUDGE = "/kms/nudge/"
const KMSMAXMIN = "/forms/peoples/mileage/maxmin/"
const KMREPORT = "/reports/mileage/"
const KMREPORTITEMS = "/reports/items/mileage/"
const HOLIDAY = "/forms/holidays/mileage/"
const KMSBALANCE = "/forms/mileage-balance"

export default {
    getKm(id) {
        return Api.http(true).get(`${KMS + id}/`)
    },
    getKms(year, month, id = "") {
        if (id !== "") {
            return Api.http(true).get(
                `${KMS}?date__year=${year}&date__month=${month}&employee_id=${id}`
            )
        }
        return Api.http(true).get(
            `${KMS}?date__year=${year}&date__month=${month}`
        )
    },
    kmsPut(data) {
        return Api.http(true).put(`${KMS + data.id}/`, data)
    },
    getKmEntries(id) {
        return Api.http(true).get(`${KMENTRIES}?km_map_id=${id}`)
    },
    getPeoplesKms(year, month) {
        return Api.http(true).get(
            `${PEOPLESKMS}?date__year=${year}&date__month=${month}`
        )
    },
    getPeoplesMileage(year, month) {
        return Api.http(true, `${Constants.API_BASEURL}v2`).get(
            `${PEOPLESKMS}?date__year=${year}&date__month=${month}`
        )
    },
    updateOrCreatePeoplesKms(data) {
        if ("id" in data) {
            if ("attachment" in data) {
                const formData = new FormData()
                for (const [key, value] of Object.entries(data)) {
                    formData.append(key, value)
                }
                return Api.http(true, `${Constants.API_BASEURL}v2`).put(
                    `${PEOPLESKMS + data.id}/`,
                    formData
                )
            }
            return Api.http(true, `${Constants.API_BASEURL}v2`).put(
                `${PEOPLESKMS + data.id}/`,
                data
            )
        }
        return Api.http(true, `${Constants.API_BASEURL}v2`).post(
            PEOPLESKMS,
            data
        )
    },
    deletePeoplesKms(id) {
        return Api.http(true, `${Constants.API_BASEURL}v2`).delete(
            `${PEOPLESKMS + id}/`
        )
    },
    downloadEntries(id, type = "excel") {
        if (type === "excel") {
            return Api.http(true).get(`${KMENTRIES_EXPORT + id}/`, {
                responseType: "blob",
            })
        }
        return Api.http(true).get(`${KMENTRIES_EXPORT + id}/?export=pdf`, {
            responseType: "blob",
        })
    },
    postNudge(data) {
        return Api.http(true).post(NUDGE, data)
    },
    getMessage(id) {
        return Api.http(true).get(`${MESSAGE}?km_id=${id}`)
    },
    postMessage(data) {
        return Api.http(true).post(MESSAGE, data)
    },
    deleteMessage(id) {
        return Api.http(true).delete(`${MESSAGE + id}/`)
    },
    getMinMaxDate() {
        return Api.http(true).get(KMSMAXMIN)
    },
    rmEntry(id) {
        return Api.http(true).delete(`${KMENTRIES + id}/`)
    },
    updateOrCreateKmEntry(data) {
        if ("id" in data) {
            return Api.http(true).put(`${KMENTRIES + data.id}/`, data)
        }
        return Api.http(true).post(KMENTRIES, data)
    },
    getEntry(id) {
        return Api.http(true).get(`${KMENTRIES + id}/`)
    },
    getKmReport() {
        return Api.http(true).get(KMREPORT)
    },
    getKmReportItems(date) {
        return Api.http(true).get(`${KMREPORTITEMS}?report__date=${date}`)
    },
    myKms(id) {
        return Api.http(true).get(`${PEOPLESKMS}?my=1&employee_id=${id}`)
    },
    getFile(link) {
        return Api.https(true, Constants.URL).get(link, {
            responseType: "blob",
        })
    },
    getHoliday(date) {
        return Api.http(true).get(`${HOLIDAY}?date=${date}`)
    },
    getBalance({ date, employee }) {
        return Api.http(true).get(
            `${KMSBALANCE}/?date=${date}&employee_id=${employee}`
        )
    },
}
